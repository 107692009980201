import { useLayoutEffect, useState } from "react";

const ANIMATION_SPEED = 12;

/**
 * Animates the scrolling (scrollLeft) of the given container to a given position (to).
 * @param {node} container
 * @param {number} to
 */
function animateScroll(container, to) {
  let current = container.scrollLeft;
  frame();

  function frame() {
    const id = requestAnimationFrame(frame);
    current += (to - current) / ANIMATION_SPEED;
    container.scrollLeft = current;

    // End is reached.
    if (Math.round(current) === to) {
      container.scrollLeft = to;
      cancelAnimationFrame(id);
    }
  }
}

function useScrollAnimation({ containerRef, childClass }) {
  const [scrollAmount, setScrollAmount] = useState(0);

  useLayoutEffect(() => {
    let didCancel = false;

    // Sets the width of the second child (amount to scroll), because the first-child may be styled differently.
    if (containerRef.current && !didCancel) {
      const child = containerRef.current.querySelectorAll(`.${childClass}`)[1];
      if (child) {
        setScrollAmount(child.clientWidth);
      }

      // Initialize current scrolling.
      containerRef.current.scrollLeft = 0;
    }

    return () => {
      didCancel = true;
    };
  }, []); // eslint-disable-line

  return function handleClick(direction) {
    const { scrollLeft } = containerRef.current;

    const to =
      direction === "next"
        ? scrollLeft + scrollAmount
        : scrollLeft - scrollAmount;

    animateScroll(containerRef.current, to);
  };
}

export default useScrollAnimation;
