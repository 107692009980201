import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { complement, isEmpty, pathOr, propOr } from "ramda";
import React from "react";

import CaseCarousel from "../../components/Carousel/Case";
import ContactHighlight from "../../components/ContactHighlight";
import Heading from "../../components/Heading";
import Hero from "../../components/Hero/v4";
import Introduction from "../../components/Introduction";
import Modular from "../../components/Modular";
import SEO from "../../components/SEO";
import { PRODUCTS_PATH } from "../../constants";
import { caseItem } from "../../types";
import formatCase from "../../utils/formatters/formatCase";
import prettifyUrl from "../../utils/prettifyUrl";

const notEmpty = complement(isEmpty);

const Case = ({
  data: {
    data: { caseItem, formLabels },
  },
}) => {
  const {
    title,
    description,
    heroImage,
    heroVideo,
    tagline,
    customer,
    products,
    awards,
    site,
    contactHighlight,
    relatedCases,
    content,
    seoMetaTags,
  } = caseItem;

  const formattedRelatedCases = relatedCases.map((relatedCase) =>
    formatCase(relatedCase)
  );

  const getHeroCopy = propOr(
    pathOr("Greenberry", ["customer", "name"]),
    "heroBackgroundCopy"
  );
  const backgroundText = getHeroCopy(caseItem);

  return (
    <>
      <SEO tags={seoMetaTags} />
      <Hero
        title={
          <Heading color="primary" size={1}>
            {title}
          </Heading>
        }
        content={
          <>
            <Introduction>{description}</Introduction>
            <Heading size={3} as="p">
              {tagline}
            </Heading>
          </>
        }
        image={heroImage}
        video={heroVideo}
        backgroundCopy={[backgroundText]}
        info={[
          {
            label: "Klant",
            items: [{ value: customer.name }],
          },
          {
            label: "Producten",
            items: products.map(({ title, slug }) => {
              return {
                value: title,
                url: `${PRODUCTS_PATH}/${slug}`,
              };
            }),
          },
          {
            label: "Awards",
            items: awards && [{ value: awards }],
          },
          {
            label: "Site",
            items: site && [{ value: prettifyUrl(site), url: site }],
          },
        ]}
      />

      {content.filter(notEmpty).map((item) => {
        if (item.type == "DatoCMS_SignupRecord") {
          item.formLabels = formLabels;
        }
        return <Modular key={item.id} {...item} type={item.type} />;
      })}

      {contactHighlight && <ContactHighlight {...contactHighlight} />}
      {relatedCases.length > 0 && (
        <CaseCarousel items={formattedRelatedCases} />
      )}
    </>
  );
};

Case.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      caseItem: PropTypes.shape(caseItem).isRequired,
      formLabels: PropTypes.object,
    }).isRequired,
  }).isRequired,
};

export default Case;

export const query = graphql`
  query getCase($slug: String!) {
    data: dato {
      caseItem: case(filter: { slug: { eq: $slug } }) {
        seoMetaTags: _seoMetaTags {
          tag
          attributes
          content
        }
        customer {
          name
        }
        id
        slug
        title
        tagline
        awards
        site
        highlighted
        description
        heroBackgroundCopy
        heroImage {
          data: responsiveImage(
            imgixParams: { fit: crop, w: 1280, fm: jpg, auto: format }
          ) {
            ...responsiveImageFragment
          }
        }
        heroVideo {
          ...MuxVideoFragment
        }
        products {
          title
          slug
        }
        contactHighlight {
          ...contactHighlightFragment
        }
        relatedCases {
          ...caseFragment
        }
        content {
          ...videoFragment
          ...imageFragment
          ...basicTitleDescriptionFragment
          ...textImageFragment
          ...inlineCarouselFragment
          ...imageCarouselFragment
          ...textColumnFragment
          ...quoteFragment
          ...narrowTaglineFragment
          ...casesHighlightFragment
          ...signupFragment
        }
      }
      formLabels: global {
        newsletterButtonText
        beforePrivacyPolicy
        afterPrivacyPolicy
        privacyPolicyLinkText
        privacyPolicyPath
      }
    }
  }
`;
