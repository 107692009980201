import styled from "@emotion/styled";
import { motion, useAnimation } from "framer-motion";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import usePrefersReducedMotion from "../../../hooks/usePrefersReducedMotion";
import theme from "../../../theme";
import mq from "../../../utils/mediaQuery";
import CaseItem, { caseItem } from "../../CaseItem/v2";
import CustomCursor from "../../CustomCursor";
import { NEXT_ICON, PREV_ICON } from "../constants";
import useScrollAnimation from "./animation";

const CASE_CLASSNAME = "carousel-case-item";
const ITEM_WIDTH = 530;

const Wrapper = styled.div`
  position: relative;
`;

const OuterScrollWrapper = styled.div`
  overflow: auto;
  background: ${theme.color("blue.light")};
  -webkit-overflow-scrolling: touch;

  ${mq("2")} {
    overflow: hidden;
  }
`;

const InnerScrollWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
`;

const CarouselCaseItemWrapper = styled.div`
  padding: ${theme.space("l")} ${theme.space("s")};
  padding-right: 0;

  &:last-of-type {
    padding-right: ${theme.space("m")};
  }

  ${mq("2")} {
    padding-top: ${theme.space("xxl")};
    padding-bottom: ${theme.space("xxl")};

    &:first-of-type {
      padding-left: ${theme.space("xxl")};
    }

    &:last-of-type {
      padding-right: ${theme.space("xxl")};
    }
  }
`;

const CarouselCaseItem = styled(CaseItem)`
  flex: 0 0 auto;
  width: 68vw;
  max-width: 284px;

  ${mq("2")} {
    width: 64vw;
    max-width: ${ITEM_WIDTH}px;
  }

  a {
    position: relative;
    z-index: 99;
  }
`;

const CursorOverlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

function CaseCarousel({ items, reveal, ...props }) {
  const ref = useRef();
  const [centerItems, setCenterItems] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  const animate = useScrollAnimation({
    containerRef: ref,
    childClass: CASE_CLASSNAME,
  });

  const controls = useAnimation();
  const containerMotion = {
    start: theme.get("animation.staggeredReveal"),
    end: theme.get("animation.staggeredReveal"),
  };

  const itemMotion = {
    start: {
      y: 100,
      opacity: 0,
      transition: theme.get("animation.reveal.transition"),
    },
    end: {
      y: 0,
      opacity: 1,
      transition: theme.get("animation.reveal.transition"),
    },
  };

  useEffect(() => {
    reveal && controls.start("end");
  }, [reveal, controls]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setCenterItems(window.innerWidth > items.length * ITEM_WIDTH);
    }
  }, [items]);

  return (
    <Wrapper>
      <motion.div
        variants={containerMotion}
        initial={reveal && !prefersReducedMotion && "start"}
        animate={controls}
      >
        <OuterScrollWrapper {...props} ref={ref}>
          <InnerScrollWrapper center={centerItems}>
            {items.map(({ id, ...item }) => (
              <motion.div key={id} variants={itemMotion}>
                <CarouselCaseItemWrapper className={CASE_CLASSNAME}>
                  <CarouselCaseItem {...item} />
                </CarouselCaseItemWrapper>
              </motion.div>
            ))}
          </InnerScrollWrapper>
        </OuterScrollWrapper>
      </motion.div>
      {!centerItems && (
        <CursorOverlay>
          <CustomCursor
            cursorImage={PREV_ICON}
            onClick={() => animate("prev")}
          />
          <CustomCursor
            cursorImage={NEXT_ICON}
            onClick={() => animate("next")}
          />
        </CursorOverlay>
      )}
    </Wrapper>
  );
}

CaseCarousel.propTypes = {
  reveal: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      ...caseItem,
    })
  ),
};

CaseCarousel.defaultProps = {
  items: [],
};

export default CaseCarousel;
