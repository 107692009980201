import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { number, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";

const Container = styled.div`
  height: 100%;
  width: 100%;
  cursor: none;
  position: relative;
  overflow: hidden;
  z-index: 2;
`;

const Cursor = styled.span(
  ({ x, y, size, imageUrl, show }) => css`
    background: transparent url("${imageUrl}") center/cover no-repeat;
    height: ${size}rem;
    width: ${size}rem;
    display: block;
    visibility: ${show ? "visible" : "hidden"};
    // Calculation to compensate for the size of the icon and center it on cursor.
    transform: translate(
      calc(${x - (size / 2) * 16}px),
      calc(${y - (size / 2) * 16}px)
    );
  `
);

const CustomCursor = ({ cursorImage, size = 4, ...props }) => {
  const containerRef = useRef();
  const [showCursor, setShowCursor] = useState(false);
  const [coordinates, setCoordinates] = useState({ x: null, y: null });

  const onMouseEnter = () => setShowCursor(true);
  const onMouseLeave = () => setShowCursor(false);

  useEffect(() => {
    let didCancel = false;
    const containerElement = containerRef && containerRef.current;

    const handleSetCoordinates = (e) => {
      const offsetTop = containerElement.getBoundingClientRect().top;
      const offsetLeft = containerElement.getBoundingClientRect().left;
      if (!didCancel) {
        setCoordinates({
          x: e.clientX - offsetLeft,
          y: e.clientY - offsetTop,
        });
      }
    };

    containerElement.addEventListener("mousemove", handleSetCoordinates);
    containerElement.addEventListener("mouseenter", onMouseEnter);
    containerElement.addEventListener("mouseleave", onMouseLeave);
    return () => {
      didCancel = true;
      containerElement.removeEventListener("mousemove", handleSetCoordinates);
      containerElement.removeEventListener("mouseenter", onMouseEnter);
      containerElement.removeEventListener("mouseleave", onMouseLeave);
    };
  }, []);

  return (
    <Container ref={containerRef} {...props}>
      <Cursor
        show={showCursor}
        imageUrl={cursorImage}
        x={coordinates.x}
        y={coordinates.y}
        size={size}
      />
    </Container>
  );
};

CustomCursor.propTypes = {
  cursorImage: string.isRequired,
  size: number,
};

export default CustomCursor;
